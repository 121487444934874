import {observe} from 'selector-observer'

function onDragenter(event: DragEvent) {
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-gist-dropzone')!.classList.remove('d-none')
  event.stopPropagation()
  event.preventDefault()
}

function onDragleave(event: DragEvent) {
  const target = event.target
  if (target instanceof Element) {
    if (target.classList.contains('js-gist-dropzone')) {
      /* eslint-disable-next-line github/no-d-none */
      target.classList.add('d-none')
    }
  }
}

async function doDrop(event: DragEvent) {
  const transfer = event.dataTransfer
  if (!transfer) return

  for (const file of transfer.files) {
    try {
      const detail = await readTextFile(file)
      event.target!.dispatchEvent(new CustomEvent('gist:filedrop', {bubbles: true, detail}))
    } catch (e) {
      // Do nothing.
    }
  }
}

function onDrop(event: DragEvent) {
  doDrop(event)
  /* eslint-disable-next-line github/no-d-none */
  document.querySelector<HTMLElement>('.js-gist-dropzone')!.classList.add('d-none')
  event.stopPropagation()
  event.preventDefault()
}

observe('.js-gist-dropzone', {
  add() {
    document.body.addEventListener('dragenter', onDragenter)
    document.body.addEventListener('dragleave', onDragleave)
    document.body.addEventListener('dragover', onDragenter)
    document.body.addEventListener('drop', onDrop)
  },
  remove() {
    document.body.removeEventListener('dragenter', onDragenter)
    document.body.removeEventListener('dragleave', onDragleave)
    document.body.removeEventListener('dragover', onDragenter)
    document.body.removeEventListener('drop', onDrop)
  }
})

function readTextFile(file: File) {
  return new Promise(function (resolve, reject) {
    const reader = new FileReader()
    reader.onload = function () {
      const data = reader.result as string
      if (data && !/\0/.test(data)) {
        resolve({file, data})
      } else {
        reject(new Error('invalid file'))
      }
    }
    reader.readAsText(file)
  })
}
